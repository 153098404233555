<script>
import Header from "./components/HeaderItem.vue";
import Footer from "./components/FooterItem.vue";
import FloatingButton from "./components/FloatingButton.vue";
//import Home from "./views/HomeView.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    FloatingButton
},
};
</script>
<template>
  <div class="lg:max-w-[1920px] lg:mx-auto">
    <FloatingButton/>
    <Header/>
    <router-view />
    <Footer/>
  </div>
</template>
<style>
</style>
