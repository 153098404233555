<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

let isOpen = ref(false);
const route = useRoute();
watch(
  () => route.name,
  () => {
    // console.log(`MyCoolComponent - watch route.name changed to ${route.name}`);
    isOpen.value = false;

    // Optionally you can set immediate: true config for the watcher to run on init
    //}, { immediate: true });
  }
);
</script>
<template>
  <header class="py-10 lg:block hidden">
    <nav class="max-w-5xl mx-auto">
      <ul class="flex justify-center space-x-10 items-center">
        <li class=""><router-link to="/about">About</router-link></li>
        <li class=""><router-link to="/products">Products</router-link></li>
        <li>
          <div>
            <a href="/">
            <img
              class=""
              width="50"
              height="50"
              src="@/assets/images/logo.png"
              alt="logo"
            />
          </a>
          </div>
        </li>
        <li><router-link to="/projects">Projects</router-link></li>

       
        <!-- <li><router-link to="/press">Press</router-link></li>
        <li><router-link to="/blogs">Blogs</router-link></li> -->
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
    </nav>
  </header>
  <header class="py-4 px-4 lg:hidden block">
    <div class="flex justify-between">
      <div>
        <a href="/">
        <img
          class=""
          width="50"
          height="50"
          src="@/assets/images/logo.png"
          alt="logo"
        />
      </a>
      </div>
      <div @click="isOpen = !isOpen">
        <img
          v-if="!isOpen"
          class=""
          width="35"
          height="35"
          src="@/assets/images/icons/menu.png"
          alt="logo"
        />
        <img
          v-else
          class=""
          width="35"
          height="35"
          src="@/assets/images/icons/close.png"
          alt="logo"
        />
      </div>
    </div>
    <nav v-if="isOpen" class="mx-auto h-screen mt-16">
      <ul class="flex flex-col justify-center space-y-10 items-center">
        <li class=""><router-link to="/about">About</router-link></li>
        <li class=""><router-link to="/products">Products</router-link></li>
        <li><router-link to="/projects">Projects</router-link></li>
        <li><router-link to="/press">Press</router-link></li>
        <li><router-link to="/blogs">Blogs</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
    </nav>
  </header>
</template>
<style scoped>
ul > li {
  @apply text-sm uppercase tracking-[3.8px];
}
</style>