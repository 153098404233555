import { createWebHistory, createRouter } from "vue-router";
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/HomeView.vue")
  },
  {
    path: "/about",
    name: "About",
    component: ()=> import("@/views/AboutView.vue")
  },
  {
    path: "/projects",
    name: "Projects",
    component: ()=> import("@/views/ProjectsView.vue")
  },
  {
    path: "/products",
    name: "Products",
    component: ()=> import("@/views/ProductsView.vue")
  },
  {
    path: "/press",
    name: "Press",
    component: ()=> import("@/views/PressView.vue")
  },
  {
    path: "/projectind/:projectName",
    name: "Project Ind",
    params:true,
    component: ()=> import("@/views/ProjectInd.vue")
  },
  {
    path: "/productind/:productName",
    name: "Product Ind",
    params:true,
    component: ()=> import("@/views/ProductInd.vue")
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: ()=> import("@/views/BlogView.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: ()=> import("@/views/ContactView.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;