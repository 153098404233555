<template>
    <a href="https://api.whatsapp.com/send?phone=917619556669" target="_blank">
        <div class="bg-cb-gold border-[3px] border-[#000] rounded-[35px] fixed bottom-[10%] right-[5%] z-[100] w-[200px] lg:flex hidden items-center justify-between ">
            <span class="pl-4 font-bold text-sm">MESSAGE US</span>
            <div class="rounded-full bg-[#C6A564] w-[50px] h-[50px]  message-button relative flex items-center">
                <img class="transform rotate-45 mx-auto" width="20" src="/images/messenger.png"/>
            </div>
        </div>
    </a>
</template>
<script setup></script>
<style scoped>
.message-button{
  border-radius: 50px;
  border: 3px solid black;
  border-right: 0px solid transparent;
  border-bottom: 0px solid transparent;
  -webkit-transform:rotate(-45deg);
}
</style>