<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const test = () =>{
  console.log('hello');
  //router.push('/contact')
}
</script>
<template>
  <footer class="bg-cb-green lg:h-[300px] px-10 py-10 relative lg:overflow-hidden">
    <img  class="absolute top-0 left-[30%] overflow-hidden h-[300px] hidden lg:block" src="@/assets/images/home/footer/vertical.svg"/>
    <img  class="absolute bottom-0 left-0 overflow-hidden w-[50%] hidden lg:block" src="@/assets/images/home/footer/horizontal.svg"/>
    <div
      class="
        flex
        lg:gap-0
        gap-10
        lg:justify-between
        justify-center
        items-center
        lg:items-start lg:flex-row
        flex-col
      "
    >
      <div>
        <router-link to="/">
          <img
            width="60"
            height="60"
            src="@/assets/images/footer-logo.png"
            alt="logo"
          />
        </router-link>
      </div>
      <div>
        <h1
          class="
            text-xl
            lg:text-left
            text-center
            font-heading
            tracking-[2px]
            text-white
          "
        >
          Work with us
        </h1>
        <a
          class="text-white lg:text-left text-center"
          href="mailto:info@caneboutique.com"
          >info@caneboutique.com</a
        >
      </div>
    </div>
    <div
      class="
        flex
        lg:flex-row
        flex-col
        justify-center
        items-center
        lg:justify-between lg:items-end lg:mt-24
        mt-10
      "
    >
      <div
        class="
          grid grid-cols-2 grid-rows-3
          align-middle
          cursor-pointer
          place-content-center
          link-grid
          lg:gap-x-16
          gap-y-2
          z-10
        "
      >
        <div class="cursor-pointer">
          <a href="/contact">
            <span>Contact us</span>
          </a>
        </div>
        <div class="cursor-pointer">
          <a href="/contact">
            <span>Terms & Conditions</span>
          </a>
        </div>
        <div class="cursor-pointer">
          <a href="/about">
            <span>About Cane Boutique</span>
          </a>
        </div>
        <div class="cursor-pointer">
          <a href="/press">
            <span>Press Coverage</span>
          </a>
        </div>
        <div class="cursor-pointer">
          <a href="/contact">
            <span>Find a store</span>
          </a>
        </div>
    
       
        <!-- <a href=""></a>
        <a href="">Shipping</a>
        <a href="/about">About Cane Boutique</a>
        <a href="/press">Press Coverage</a>
        <a href="/contact">Find a store</a> -->
      </div>
      <div>
        <div
          class="flex gap-6 lg:justify-end justify-center mb-3 lg:mt-0 mt-10"
        >
          <a href="tel:+917619556669">
          <img
            class="w-4 h-4"
            src="@/assets/images/social/phone.svg"
            alt="phone"
          />
          </a>
          <a href="mailto:info@caneboutique.com" >
          <img
            class="w-4 h-4"
            src="@/assets/images/social/email.svg"
            alt="email"
          />
           </a>
           <a href="https://www.facebook.com/Caneboutique/" >
            <img
              class="w-4 h-4"
              src="@/assets/images/social/facebook.svg"
              alt="facebook"
             />
          </a>
          <a href="https://twitter.com/caneboutique">
          <img
            class="w-4 h-4"
            src="@/assets/images/social/twitter.svg"
            alt="twitter"
          />
          </a>
          <a href="https://www.instagram.com/cane_boutique/">
          <img
            class="w-4 h-4"
            src="@/assets/images/social/instagram.svg"
            alt="instagram"
          />
         </a>
        </div>
        <div class="text-center">
          <span class="font-thin text-white text-center lg:text-left"
            >&#169; 2023 Cane Boutique. All rights Reserved</span
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<style scoped>
.link-grid > div {
  @apply text-white font-thin cursor-pointer;
}
</style>